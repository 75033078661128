import timezones from "./files/timezones.json";

export const THUMB_H = 480;
export const THUMB_W = 640;
export const ORIG_H = 3040;
export const ORIG_W = 4032;
export const rx = ORIG_H / THUMB_H;
export const ry = ORIG_W / THUMB_W;

export const bleInfoTable = {
  services: {
    scan: "0a70321c-3679-44f4-9180-2715162d1fc7",
    connect: "4e9b31c3-f6a7-4c91-a0cf-ad0d09f8ede2",
    mac: "1536f1db-d0ad-4b80-a144-8f7f1a005bf6",
  },
  characteristics: {
    scan: {
      active: "869e4c2d-2df4-48ff-a61d-07f8cdd58341",
      list1: "30fbbc2c-adce-4795-af91-7cda7e681cb8",
      list2: "c2c72213-752f-4d48-af7c-efed87edadb9",
      list3: "00a5dd72-01b5-44f2-9b73-777bd6da3dda",
      list4: "d1f34740-cade-47b5-8d02-51ab534ad711",
      list5: "8d9f4d87-1be9-4fd1-a564-531401d2dd11",
    },
    connect: {
      wpa: "807ba565-30b5-435b-9c42-c01778aadd50",
      wclose: "75527e54-d26a-4b6d-b468-b66d81598f5c",
    },
    mac: {
      addr: "c4ed2e86-5301-4767-b337-def2391fcdc0",
    },
  },
};
export const gonotrophicAutoCompleteOptions = [
  "gravid",
  "blood fed",
  "unfed",
  "partially blood fed",
];

export const lifeStatusAutoCompleteOptions = ["adult", "nymph", "larvae"];

export const genusSpeciesAutoCompleteOptions = {
  mosquito: [
    "Aedeomyia africana",
    "Aedeomyia furfurea",
    "Aedes mansoniauri",
    "Aedes abnormalis",
    "Aedes abserratus",
    "Aedes adami",
    "Aedes adersi",
    "Aedes aegypti",
    "Aedes aerarius",
    "Aedes africanus",
    "Aedes albicosta",
    "Aedes albineus",
    "Aedes albocephalus",
    "Aedes albomarginatus",
    "Aedes albopictus",
    "Aedes albothorax",
    "Aedes alboventralis",
    "Aedes amaltheus",
    "Aedes angustus",
    "Aedes apicoannulatus",
    "Aedes apicoargenteus",
    "Aedes argenteopunctatus",
    "Aedes argenteoventralis",
    "Aedes atlanticus",
    "Aedes atropalpus",
    "Aedes aurifer",
    "Aedes aurovenatus",
    "Aedes bambiotai",
    "Aedes bambusae",
    "Aedes bananea",
    "Aedes bancoi",
    "Aedes barnardi",
    "Aedes bedfordi",
    "Aedes begeradi",
    "Aedes bequaerti",
    "Aedes bergerardi",
    "Aedes berlandi",
    "Aedes bevisi",
    "Aedes biskraensis",
    "Aedes blacklocki",
    "Aedes bolense",
    "Aedes boneti",
    "Aedes breedensis",
    "Aedes bromeliae",
    "Aedes caballus",
    "Aedes calceatus",
    "Aedes caliginosus",
    "Aedes campestris",
    "Aedes canadensis",
    "Aedes cantator",
    "Aedes capensis",
    "Aedes carteri",
    "Aedes caspius",
    "Aedes cataphylla",
    "Aedes centropunctatus",
    "Aedes chamboni",
    "Aedes chaussieri",
    "Aedes chelli",
    "Aedes cinereus",
    "Aedes circumluteolus",
    "Aedes coluzzii",
    "Aedes communis",
    "Aedes contiguus",
    "Aedes cordellieri",
    "Aedes corneti",
    "Aedes cozi",
    "Aedes crassiforceps",
    "Aedes cumminsii",
    "Aedes dalzieli",
    "Aedes deboeri",
    "Aedes demeilloni",
    "Aedes denderensis",
    "Aedes dendrophilus",
    "Aedes dentatus",
    "Aedes detritus",
    "Aedes dialloi",
    "Aedes diantaeus",
    "Aedes domesticus",
    "Aedes dorsalis",
    "Aedes dupreei",
    "Aedes durbanensis",
    "Aedes ealaensis",
    "Aedes ebogoensis",
    "Aedes echinus",
    "Aedes ellinorae",
    "Aedes embuensis",
    "Aedes eritreae",
    "Aedes ethiopiensis",
    "Aedes euedes",
    "Aedes excrucians",
    "Aedes falabreguesi",
    "Aedes fascipalpis",
    "Aedes filicis",
    "Aedes fitchii",
    "Aedes flavescens",
    "Aedes flavicollis",
    "Aedes flavimargo",
    "Aedes fowleri",
    "Aedes fraseri",
    "Aedes fryeri",
    "Aedes fulgens",
    "Aedes fulvus pallens",
    "Aedes furcifer",
    "Aedes fuscinervis",
    "Aedes gandaensis",
    "Aedes geniculatus",
    "Aedes geoffroyi",
    "Aedes gibbinsi",
    "Aedes gilliesi",
    "Aedes grahamii",
    "Aedes grenieri",
    "Aedes grjebinei",
    "Aedes grossbecki",
    "Aedes hancocki",
    "Aedes hansfordi",
    "Aedes harrisoni",
    "Aedes haworthi",
    "Aedes heischi",
    "Aedes hendersoni",
    "Aedes hexodontus",
    "Aedes hirsutus",
    "Aedes holocinctus",
    "Aedes hopkinsi",
    "Aedes huangae",
    "Aedes impiger",
    "Aedes implicatus",
    "Aedes infirmatus",
    "Aedes ingrami",
    "Aedes insolens",
    "Aedes intrudens",
    "Aedes irritans",
    "Aedes j. japonicus",
    "Aedes jamoti",
    "Aedes japonicus",
    "Aedes josiahae",
    "Aedes juppi",
    "Aedes kapretwae",
    "Aedes karooensis",
    "Aedes keniensis",
    "Aedes kennethi",
    "Aedes kenya",
    "Aedes kivuensis",
    "Aedes kummi",
    "Aedes lamborni",
    "Aedes langata",
    "Aedes ledgeri",
    "Aedes leesoni",
    "Aedes leptolabis",
    "Aedes leucarthrius",
    "Aedes lilii",
    "Aedes lineatopennis",
    "Aedes lokojensis",
    "Aedes longipalpis",
    "Aedes longiseta",
    "Aedes lottei",
    "Aedes lucianus",
    "Aedes luridus",
    "Aedes luteocephalus",
    "Aedes luteolateralis",
    "Aedes luteostriatus",
    "Aedes mariae",
    "Aedes marshallii",
    "Aedes masseyi",
    "Aedes mattinglyi",
    "Aedes mattinglyorum",
    "Aedes maxgermaini",
    "Aedes mcintoshi",
    "Aedes mefouensis",
    "Aedes melanimon",
    "Aedes mercurator",
    "Aedes metallicus",
    "Aedes michaelikati",
    "Aedes mickevichae",
    "Aedes microstictus",
    "Aedes minutus",
    "Aedes mitchellae",
    "Aedes mixtus",
    "Aedes monotrichus",
    "Aedes mpusiensis",
    "Aedes mucidus",
    "Aedes muroafcete",
    "Aedes mutilus",
    "Aedes mzooi",
    "Aedes natalensis",
    "Aedes natronius",
    "Aedes neoafricanus",
    "Aedes nevadensis",
    "Aedes ngong",
    "Aedes nigerrimus",
    "Aedes nigricephalus",
    "Aedes nigromaculis",
    "Aedes niphadopsis",
    "Aedes njombiensis",
    "Aedes nyasae",
    "Aedes nyounae",
    "Aedes ochraceus",
    "Aedes opok",
    "Aedes ovazzai",
    "Aedes pachyurus",
    "Aedes palpalis",
    "Aedes pembaensis",
    "Aedes phyllolabis",
    "Aedes pionips",
    "Aedes pogonurus",
    "Aedes pollinctor",
    "Aedes poweri",
    "Aedes provocans",
    "Aedes pseudoafricanus",
    "Aedes pseudonigeria",
    "Aedes pseudotarsalis",
    "Aedes pubescens",
    "Aedes pulchrithorax",
    "Aedes pulcritarsis",
    "Aedes pullatus",
    "Aedes punctocostalis",
    "Aedes punctor",
    "Aedes punctothoracis",
    "Aedes quasirusticus",
    "Aedes quasiunivittatus",
    "Aedes reali",
    "Aedes rickenbachi",
    "Aedes riparius",
    "Aedes rusticus",
    "Aedes ruwenzori",
    "Aedes saimedres",
    "Aedes sampi",
    "Aedes scatophagoides",
    "Aedes schizopinax",
    "Aedes schwetzi",
    "Aedes seampi",
    "Aedes semlikiensis",
    "Aedes sierrensis",
    "Aedes simpsoni",
    "Aedes simulans",
    "Aedes smithburni",
    "Aedes soleatus",
    "Aedes sollicitans",
    "Aedes spencerii",
    "Aedes spencerii idahoensis",
    "Aedes spencerii spencerii",
    "Aedes spp",
    "Aedes stenoscutus",
    "Aedes sticticus",
    "Aedes stimulans",
    "Aedes stokesi",
    "Aedes strelitziae",
    "Aedes subargenteus",
    "Aedes subdentatus",
    "Aedes sudanensis",
    "Aedes taeniarostris",
    "Aedes taeniorhynchus",
    "Aedes tarsalis",
    "Aedes tauffliebi",
    "Aedes taylori",
    "Aedes teesdalei",
    "Aedes thibaulti",
    "Aedes tormentor",
    "Aedes tricholabis",
    "Aedes triseriatus",
    "Aedes trivittatus",
    "Aedes unidentatus",
    "Aedes unilineatus",
    "Aedes usambara",
    "Aedes veeniae",
    "Aedes ventrovittis",
    "Aedes vexans",
    "Aedes vittatus",
    "Aedes wellmanii",
    "Aedes wendyae",
    "Aedes wigglesworthi",
    "Aedes woodi",
    "Aedes yangambiensis",
    "Aedes yvonneae",
    "Aedes zethus",
    "Anopheles ainshamsi",
    "Anopheles algeriensis",
    "Anopheles amharicus",
    "Anopheles arabiensis",
    "Anopheles ardensis",
    "Anopheles argenteolobatus",
    "Anopheles aruni",
    "Anopheles atropos",
    "Anopheles austeni",
    "Anopheles austenii",
    "Anopheles azaniae",
    "Anopheles azevedoi",
    "Anopheles bangrofti",
    "Anopheles barberellus",
    "Anopheles barberi",
    "Anopheles berghei",
    "Anopheles bervoetsi",
    "Anopheles bradleyi",
    "Anopheles brohieri",
    "Anopheles brucei",
    "Anopheles brumpti",
    "Anopheles brunnipes",
    "Anopheles buxtoni",
    "Anopheles bwambae",
    "Anopheles caliginosus",
    "Anopheles cameroni",
    "Anopheles carnevalei",
    "Anopheles caroni",
    "Anopheles carteri",
    "Anopheles cavernicolus",
    "Anopheles christyi",
    "Anopheles cinctus",
    "Anopheles cinereus",
    "Anopheles claviger",
    "Anopheles coluzzii",
    "Anopheles comorensis",
    "Anopheles concolor",
    "Anopheles confusus",
    "Anopheles coustani",
    "Anopheles coustani s.l",
    "Anopheles cristipalpis",
    "Anopheles crucians",
    "Anopheles crucians sl",
    "Anopheles crypticus",
    "Anopheles culicifacies",
    "Anopheles cydippis",
    "Anopheles dancalicus",
    "Anopheles daudi",
    "Anopheles deemingi",
    "Anopheles demeilloni",
    "Anopheles distinctus",
    "Anopheles domicolus",
    "Anopheles dthali",
    "Anopheles dualaensis",
    "Anopheles dureni",
    "Anopheles earlei",
    "Anopheles eouzani",
    "Anopheles erepens",
    "Anopheles erythraeus",
    "Anopheles ethiopicus",
    "Anopheles faini",
    "Anopheles farauti s.l.",
    "Anopheles flavicosta",
    "Anopheles fontenillei",
    "Anopheles fontinalis",
    "Anopheles freeborni",
    "Anopheles freetownensis",
    "Anopheles funestus",
    "Anopheles funestus s.l",
    "Anopheles funestus s.s.",
    "Anopheles funestus-like",
    "Anopheles fuscicolor",
    "Anopheles fuscivenosus",
    "Anopheles gabonensis",
    "Anopheles gambiae",
    "Anopheles gambiae s.l",
    "Anopheles gambiae s.s",
    "Anopheles garnhami",
    "Anopheles gibbinsi",
    "Anopheles grassei",
    "Anopheles grenieri",
    "Anopheles griveaudi",
    "Anopheles hamoni",
    "Anopheles hancocki",
    "Anopheles hargreavesi",
    "Anopheles harperi",
    "Anopheles hermsi",
    "Anopheles hervyi",
    "Anopheles hughi",
    "Anopheles hyrcanus",
    "Anopheles implexus",
    "Anopheles jebudensis",
    "Anopheles keniensis",
    "Anopheles kingi",
    "Anopheles koliensis",
    "Anopheles kosiensis",
    "Anopheles labranchiae",
    "Anopheles lacani",
    "Anopheles leesoni",
    "Anopheles letabensis",
    "Anopheles listeri",
    "Anopheles lloreti",
    "Anopheles longipalpis",
    "Anopheles longirostris",
    "Anopheles lounibosi",
    "Anopheles lovettae",
    "Anopheles machardyi",
    "Anopheles maculipalpis",
    "Anopheles maculipennis",
    "Anopheles maliensis",
    "Anopheles marshalli",
    "Anopheles marshallii",
    "Anopheles marteri",
    "Anopheles mascarensis",
    "Anopheles melas",
    "Anopheles merus",
    "Anopheles millecampsi",
    "Anopheles milloti",
    "Anopheles mortiauxi",
    "Anopheles moucheti",
    "Anopheles moucheti nigeriensis",
    "Anopheles moucheti s.l",
    "Anopheles moucheti s.s",
    "Anopheles mousinhoi",
    "Anopheles multicinctus",
    "Anopheles multicolor",
    "Anopheles murphyi",
    "Anopheles namibiensis",
    "Anopheles natalensis",
    "Anopheles nigeriensis",
    "Anopheles nili",
    "Anopheles nili s.l",
    "Anopheles nili s.s",
    "Anopheles njombiensis",
    "Anopheles notleyi",
    "Anopheles obscurus",
    "Anopheles okuensis",
    "Anopheles ovengensis",
    "Anopheles paludis",
    "Anopheles parensis",
    "Anopheles pauliani",
    "Anopheles perplexens",
    "Anopheles petragnani",
    "Anopheles pharoensis",
    "Anopheles plumbeus",
    "Anopheles pretoriensis",
    "Anopheles punctipennis",
    "Anopheles punctuatus",
    "Anopheles quadriannulatus",
    "Anopheles quadrimaculatus",
    "Anopheles rabaiensis",
    "Anopheles radama",
    "Anopheles rageaui",
    "Anopheles ranci",
    "Anopheles rhodesiensis",
    "Anopheles rhodesiensis rupicolus",
    "Anopheles rivulorum",
    "Anopheles rivulorum-like",
    "Anopheles rodhaini",
    "Anopheles roubaudi",
    "Anopheles ruarinus",
    "Anopheles rufipes",
    "Anopheles sacharovi",
    "Anopheles salbaii",
    "Anopheles schwetzi",
    "Anopheles seretsei",
    "Anopheles sergentii",
    "Anopheles sergentii macmahoni",
    "Anopheles seydeli",
    "Anopheles smithii",
    "Anopheles somalicus",
    "Anopheles spp",
    "Anopheles squamosus",
    "Anopheles squamous",
    "Anopheles stephensi",
    "Anopheles superpictus",
    "Anopheles swahilicus",
    "Anopheles symesi",
    "Anopheles tchekedii",
    "Anopheles tenebrosus",
    "Anopheles theileri",
    "Anopheles turkhudi",
    "Anopheles ugandae",
    "Anopheles vaneedeni",
    "Anopheles vanhoofi",
    "Anopheles vernus",
    "Anopheles vinckei",
    "Anopheles walkeri",
    "Anopheles walravensi",
    "Anopheles watsoni",
    "Anopheles wellcomei",
    "Anopheles wellcomei erepens",
    "Anopheles wilsoni",
    "Anopheles ziemanni",
    "Coquillettidia annettii",
    "Coquillettidia aurea",
    "Coquillettidia aurites",
    "Coquillettidia buxtoni",
    "Coquillettidia chrysosoma",
    "Coquillettidia cristata",
    "Coquillettidia flavocincta",
    "Coquillettidia fraseri",
    "Coquillettidia fuscopennata",
    "Coquillettidia grandidieri",
    "Coquillettidia karandalaensis",
    "Coquillettidia maculipennis",
    "Coquillettidia metallica",
    "Coquillettidia microannulata",
    "Coquillettidia nigritarsis",
    "Coquillettidia nigrithorax",
    "Coquillettidia perturbans",
    "Coquillettidia pseudoconopas",
    "Coquillettidia richiardii",
    "Coquillettidia schoutedeni",
    "Coquillettidia spp",
    "Coquillettidia vanoyei",
    "Coquillettidia versicolor",
    "Coquillettidia voltaensis",
    "Coquillettidia wahlbergi",
    "Culex acrostichalis",
    "Culex adairi",
    "Culex adami",
    "Culex adersianus",
    "Culex albertianus",
    "Culex albiventris",
    "Culex amaniensis",
    "Culex andersoni",
    "Culex andreanus",
    "Culex annulioris",
    "Culex annulirostris",
    "Culex antennatus",
    "Culex arbieeni",
    "Culex argenteopunctatus",
    "Culex astridianus",
    "Culex aurantapex",
    "Culex avianus",
    "Culex beta",
    "Culex bitaeniorhynchus",
    "Culex brumpti",
    "Culex bukavuensis",
    "Culex calabarensis",
    "Culex calurus",
    "Culex cambournaci",
    "Culex castelli",
    "Culex castor",
    "Culex chorleyi",
    "Culex cinerellus",
    "Culex cinereus",
    "Culex decens",
    "Culex deserticola",
    "Culex duttoni",
    "Culex eouzani",
    "Culex erraticus",
    "Culex erythrothorax",
    "Culex europaeus",
    "Culex fimbriforceps",
    "Culex furlongi",
    "Culex galliardi",
    "Culex gamma",
    "Culex garioui",
    "Culex gelidus",
    "Culex germaini",
    "Culex gilliesi",
    "Culex grahamii",
    "Culex grenieri",
    "Culex guiarti",
    "Culex hamoni",
    "Culex hancocki",
    "Culex harleyi",
    "Culex helenae",
    "Culex hopkinsi",
    "Culex horridus",
    "Culex hortensis",
    "Culex impudicus",
    "Culex inconspicuosus",
    "Culex ingrami",
    "Culex insignis",
    "Culex invidiosus",
    "Culex kanyamwerima",
    "Culex kilara",
    "Culex kingianus",
    "Culex laplantei",
    "Culex laticinctus",
    "Culex liberiensis",
    "Culex lineatus",
    "Culex litwakae",
    "Culex macfiei",
    "Culex martinii",
    "Culex mijanae",
    "Culex mimeticus",
    "Culex mirificus",
    "Culex modestus",
    "Culex mongiro",
    "Culex moucheti",
    "Culex mundulus",
    "Culex musarum",
    "Culex muspratti",
    "Culex nakuruensis",
    "Culex neavei",
    "Culex nebulosus",
    "Culex nigripalpus",
    "Culex ninagongoensis",
    "Culex ornatothoracis",
    "Culex orstom",
    "Culex pajoti",
    "Culex peccator",
    "Culex perexiguus",
    "Culex perfidiosus",
    "Culex perfuscus",
    "Culex peringueyi",
    "Culex philipi",
    "Culex pipiens",
    "Culex pipiens sl",
    "Culex poicilipes",
    "Culex pruina",
    "Culex pseudoandreanus",
    "Culex pseudopruina",
    "Culex pullus",
    "Culex pusillus",
    "Culex quasiguiarti",
    "Culex quinquefasciatus",
    "Culex quintetti",
    "Culex restuans",
    "Culex richeti",
    "Culex rima",
    "Culex rubinotus",
    "Culex salinarius",
    "Culex salisburiensis",
    "Culex schwetzi",
    "Culex seldeslachtsi",
    "Culex semibrunneus",
    "Culex shoae",
    "Culex simpliciforceps",
    "Culex simpsoni",
    "Culex sinaiticus",
    "Culex sitiens",
    "Culex spp",
    "Culex stigmatosoma",
    "Culex striatipes",
    "Culex subaequalis",
    "Culex subrima",
    "Culex subsalisburiensis",
    "Culex sunyaniensis",
    "Culex tamsi",
    "Culex tarsalis",
    "Culex tauffliebi",
    "Culex telesilla",
    "Culex tenagius",
    "Culex teritans",
    "Culex territans",
    "Culex terzii",
    "Culex thalassius",
    "Culex theileri",
    "Culex toroensis",
    "Culex torrentium",
    "Culex trifilatus",
    "Culex trifoliatus",
    "Culex tritaeniorhynchus",
    "Culex umbripes",
    "Culex univittatus",
    "Culex vagans",
    "Culex vansomereni",
    "Culex vattieri",
    "Culex verutus",
    "Culex vinckei",
    "Culex vishnui",
    "Culex wansoni",
    "Culex watti",
    "Culex weschei",
    "Culex wigglesworthi",
    "Culex zombaensis",
    "Culiseta alaskaensis",
    "Culiseta annulata",
    "Culiseta fraseri",
    "Culiseta fumipennis",
    "Culiseta impatiens",
    "Culiseta incidens",
    "Culiseta inornata",
    "Culiseta litorea",
    "Culiseta longiareolata",
    "Culiseta melanura",
    "Culiseta minnesotae",
    "Culiseta morsitans",
    "Culiseta spp",
    "Culiseta subochrea",
    "Culiseta tarsalis",
    "Eretmapodites adami",
    "Eretmapodites angolensis",
    "Eretmapodites argyrurus",
    "Eretmapodites brenguesi",
    "Eretmapodites brottesi",
    "Eretmapodites caillardi",
    "Eretmapodites chrysogaster",
    "Eretmapodites corbeti",
    "Eretmapodites dracaenae",
    "Eretmapodites dundo",
    "Eretmapodites eouzani",
    "Eretmapodites ferrarai",
    "Eretmapodites forcipulatus",
    "Eretmapodites germaini",
    "Eretmapodites gilletti",
    "Eretmapodites grahami",
    "Eretmapodites grenieri",
    "Eretmapodites haddowi",
    "Eretmapodites hamoni",
    "Eretmapodites harperi",
    "Eretmapodites hightoni",
    "Eretmapodites inornatus",
    "Eretmapodites intermedius",
    "Eretmapodites jani",
    "Eretmapodites lacani",
    "Eretmapodites leucopous",
    "Eretmapodites mahaffyi",
    "Eretmapodites marcelleae",
    "Eretmapodites mattinglyi",
    "Eretmapodites melanopous",
    "Eretmapodites mortiauxi",
    "Eretmapodites oedipodeios",
    "Eretmapodites parvipluma",
    "Eretmapodites pauliani",
    "Eretmapodites penicillatus",
    "Eretmapodites plioleucus",
    "Eretmapodites productus",
    "Eretmapodites quinquevittatus",
    "Eretmapodites ravissei",
    "Eretmapodites rickenbachi",
    "Eretmapodites salauni",
    "Eretmapodites semisimplicipes",
    "Eretmapodites silvestris",
    "Eretmapodites subsimplicipes",
    "Eretmapodites tendeiroi",
    "Eretmapodites tonsus",
    "Eretmapodites vansomereni",
    "Eretmapodites wansoni",
    "Ficalbia circumtestacea",
    "Ficalbia malfeyti",
    "Ficalbia nigra",
    "Ficalbia uniformis",
    "Hodgesia cyptopus",
    "Hodgesia nigeriae",
    "Hodgesia psectropus",
    "Hodgesia sanguinae",
    "Lutzia tigripes",
    "Malaya farquharsoni",
    "Malaya fraseri",
    "Malaya marceli",
    "Malaya moucheti",
    "Malaya taeniarostris",
    "Malaya trichorostris",
    "Mansonia africana",
    "Mansonia perturbans",
    "Mansonia uniformis",
    "Mimomyia Mimomyiayiaformis",
    "Mimomyia femorata",
    "Mimomyia flavopicta",
    "Mimomyia hispida",
    "Mimomyia lacustris",
    "Mimomyia mediolineata",
    "Mimomyia pallida",
    "Mimomyia parenti",
    "Mimomyia perplexens",
    "Mimomyia plumosa",
    "Mimomyia splendens",
    "Mimomyia xanthozona",
    "Orthopodomyia alba",
    "Orthopodomyia aureoantennata",
    "Orthopodomyia nkolbissonensis",
    "Orthopodomyia ototomoensis",
    "Orthopodomyia pulcripalpis",
    "Orthopodomyia signifera",
    "Psorophora ciliata",
    "Psorophora columbiae",
    "Psorophora cyanescens",
    "Psorophora discolor",
    "Psorophora ferox",
    "Psorophora horrida",
    "Psorophora howardii",
    "Psorophora mathesoni",
    "Psorophora signipennis",
    "Psorophora spp",
    "Psorophora varipes",
    "Toxorhynchites aeneus",
    "Toxorhynchites angolensis",
    "Toxorhynchites barbipes",
    "Toxorhynchites brevipalpis",
    "Toxorhynchites camaronis",
    "Toxorhynchites dundo",
    "Toxorhynchites erythrurus",
    "Toxorhynchites evansae",
    "Toxorhynchites helenae",
    "Toxorhynchites kaimosi",
    "Toxorhynchites lutescens",
    "Toxorhynchites nairobiensis",
    "Toxorhynchites nigeriensis",
    "Toxorhynchites phytophagus",
    "Toxorhynchites rickenbachi",
    "Toxorhynchites rodhaini",
    "Toxorhynchites rutilus",
    "Toxorhynchites ruwenzori",
    "Toxorhynchites viridibasis",
    "Toxorhynchites wolfsi",
    "Toxorhynchites zairensis",
    "Uranotaenia alba",
    "Uranotaenia alboabdominalis",
    "Uranotaenia andreae",
    "Uranotaenia angolensis",
    "Uranotaenia annulata",
    "Uranotaenia apicotaeniata",
    "Uranotaenia balfouri",
    "Uranotaenia benoiti",
    "Uranotaenia bilineata",
    "Uranotaenia caeruleocephala",
    "Uranotaenia caliginosa",
    "Uranotaenia cavernicola",
    "Uranotaenia chorleyi",
    "Uranotaenia connali",
    "Uranotaenia devemyi",
    "Uranotaenia fraseri",
    "Uranotaenia fusca",
    "Uranotaenia garnhami",
    "Uranotaenia hebrardi",
    "Uranotaenia henrardi",
    "Uranotaenia henriquei",
    "Uranotaenia hopkinsi",
    "Uranotaenia lowii",
    "Uranotaenia lucyae",
    "Uranotaenia lunda",
    "Uranotaenia machadoi",
    "Uranotaenia mashonaensis",
    "Uranotaenia mashonanensis",
    "Uranotaenia mayeri",
    "Uranotaenia montana",
    "Uranotaenia musarum",
    "Uranotaenia nigripes",
    "Uranotaenia nigromaculata",
    "Uranotaenia nivipous",
    "Uranotaenia ornata",
    "Uranotaenia ototomo",
    "Uranotaenia pallidocephala",
    "Uranotaenia palmeirimi",
    "Uranotaenia philonuxia",
    "Uranotaenia principensis",
    "Uranotaenia pseudohenrardi",
    "Uranotaenia ramosa",
    "Uranotaenia rickenbachi",
    "Uranotaenia sapphirina",
    "Uranotaenia shillitonis",
    "Uranotaenia signata",
    "Uranotaenia tanzaniae",
    "Uranotaenia ugandae",
    "Uranotaenia unguiculata",
    "Uranotaenia yovani",
    "Wyeomyia smithii",
  ],
  tick: [
    "Amblyomma americanum",
    "Amblyomma auricularium",
    "Amblyomma cajennense",
    "Amblyomma cohaerens",
    "Amblyomma eburneum",
    "Amblyomma gemma",
    "Amblyomma hebraeum",
    "Amblyomma lepidum",
    "Amblyomma maculatum",
    "Amblyomma ovale",
    "Amblyomma pomposum",
    "Amblyomma sabanerae",
    "Amblyomma sparsum",
    "Amblyomma spp",
    "Amblyomma variegatum",
    "Argas persicus",
    "Argas walkerae",
    "Dermacentor albipictus",
    "Dermacentor andersoni",
    "Dermacentor marginatus",
    "Dermacentor nitens",
    "Dermacentor occidentalis",
    "Dermacentor reticulatus",
    "Dermacentor spp",
    "Dermacentor variabilis",
    "Haemaphysalis aciculifer",
    "Haemaphysalis flava",
    "Haemaphysalis inermis",
    "Haemaphysalis kitaoka",
    "Haemaphysalis leachi",
    "Haemaphysalis longicornis",
    "Haemaphysalis parmata",
    "Haemaphysalis punctata",
    "Haemaphysalis spp",
    "Hyalomma aegyptium",
    "Hyalomma albiparmatum",
    "Hyalomma asiaticum",
    "Hyalomma dromedarii",
    "Hyalomma excavatum",
    "Hyalomma glabrum",
    "Hyalomma impeltatum",
    "Hyalomma lusitanicum",
    "Hyalomma marginatum",
    "Hyalomma rufipes",
    "Hyalomma spp",
    "Hyalomma truncatum",
    "Ixodes affinis",
    "Ixodes cookei",
    "Ixodes pacificus",
    "Ixodes persulcatus",
    "Ixodes ricinus",
    "Ixodes scapularis",
    "Ixodes spp",
    "Ixodes texanus",
    "Ornithodoros moubata/porcinus",
    "Ornithodoros savignyi",
    "Rhipicephalus annulatus",
    "Rhipicephalus appendiculatus",
    "Rhipicephalus bergeoni",
    "Rhipicephalus bursa",
    "Rhipicephalus decoloratus",
    "Rhipicephalus evertsi",
    "Rhipicephalus evertsi evertsi",
    "Rhipicephalus evertsi mimeticus",
    "Rhipicephalus geigyi",
    "Rhipicephalus lunulatus",
    "Rhipicephalus microplus",
    "Rhipicephalus muhsamae",
    "Rhipicephalus praetextatus",
    "Rhipicephalus pravus",
    "Rhipicephalus pulchellus",
    "Rhipicephalus pusillus",
    "Rhipicephalus sanguineus",
    "Rhipicephalus senegalensis",
    "Rhipicephalus simus",
    "Rhipicephalus spp",
    "Rhipicephalus sulcatus",
    "Rhipicephalus turanicus",
    "Rhipicephalus zambeziensis",
  ],
};

// const tickAutoCompleteOptions = [
//   "Amblyomma americanum",
//   "Amblyomma auricularium",
//   "Amblyomma cajennense",
//   "Amblyomma cohaerens",
//   "Amblyomma eburneum",
//   "Amblyomma gemma",
//   "Amblyomma hebraeum",
//   "Amblyomma lepidum",
//   "Amblyomma maculatum",
//   "Amblyomma ovale",
//   "Amblyomma pomposum",
//   "Amblyomma sabanerae",
//   "Amblyomma sparsum",
//   "Amblyomma spp",
//   "Amblyomma variegatum",
//   "Argas persicus",
//   "Argas walkerae",
//   "Dermacentor albipictus",
//   "Dermacentor andersoni",
//   "Dermacentor marginatus",
//   "Dermacentor nitens",
//   "Dermacentor occidentalis",
//   "Dermacentor reticulatus",
//   "Dermacentor spp",
//   "Dermacentor variabilis",
//   "Haemaphysalis aciculifer",
//   "Haemaphysalis flava",
//   "Haemaphysalis inermis",
//   "Haemaphysalis kitaoka",
//   "Haemaphysalis leachi",
//   "Haemaphysalis longicornis",
//   "Haemaphysalis parmata",
//   "Haemaphysalis punctata",
//   "Haemaphysalis spp",
//   "Hyalomma aegyptium",
//   "Hyalomma albiparmatum",
//   "Hyalomma asiaticum",
//   "Hyalomma dromedarii",
//   "Hyalomma excavatum",
//   "Hyalomma glabrum",
//   "Hyalomma impeltatum",
//   "Hyalomma lusitanicum",
//   "Hyalomma marginatum",
//   "Hyalomma rufipes",
//   "Hyalomma spp",
//   "Hyalomma truncatum",
//   "Ixodes affinis",
//   "Ixodes cookei",
//   "Ixodes pacificus",
//   "Ixodes persulcatus",
//   "Ixodes ricinus",
//   "Ixodes scapularis",
//   "Ixodes spp",
//   "Ixodes texanus",
//   "Ornithodoros moubata/porcinus",
//   "Ornithodoros savignyi",
//   "Rhipicephalus annulatus",
//   "Rhipicephalus appendiculatus",
//   "Rhipicephalus bergeoni",
//   "Rhipicephalus bursa",
//   "Rhipicephalus decoloratus",
//   "Rhipicephalus evertsi",
//   "Rhipicephalus evertsi evertsi",
//   "Rhipicephalus evertsi mimeticus",
//   "Rhipicephalus geigyi",
//   "Rhipicephalus lunulatus",
//   "Rhipicephalus microplus",
//   "Rhipicephalus muhsamae",
//   "Rhipicephalus praetextatus",
//   "Rhipicephalus pravus",
//   "Rhipicephalus pulchellus",
//   "Rhipicephalus pusillus",
//   "Rhipicephalus sanguineus",
//   "Rhipicephalus senegalensis",
//   "Rhipicephalus simus",
//   "Rhipicephalus spp",
//   "Rhipicephalus sulcatus",
//   "Rhipicephalus turanicus",
//   "Rhipicephalus zambeziensis",
// ];

// export const genusSpeciesAutoCompleteOptions = [
//   "Aedeomyia africana",
//   "Aedeomyia furfurea",
//   "Aedes mansoniauri",
//   "Aedes abnormalis",
//   "Aedes abserratus",
//   "Aedes adami",
//   "Aedes adersi",
//   "Aedes aegypti",
//   "Aedes aerarius",
//   "Aedes africanus",
//   "Aedes albicosta",
//   "Aedes albineus",
//   "Aedes albocephalus",
//   "Aedes albomarginatus",
//   "Aedes albopictus",
//   "Aedes albothorax",
//   "Aedes alboventralis",
//   "Aedes amaltheus",
//   "Aedes angustus",
//   "Aedes apicoannulatus",
//   "Aedes apicoargenteus",
//   "Aedes argenteopunctatus",
//   "Aedes argenteoventralis",
//   "Aedes atlanticus",
//   "Aedes atropalpus",
//   "Aedes aurifer",
//   "Aedes aurovenatus",
//   "Aedes bambiotai",
//   "Aedes bambusae",
//   "Aedes bananea",
//   "Aedes bancoi",
//   "Aedes barnardi",
//   "Aedes bedfordi",
//   "Aedes begeradi",
//   "Aedes bequaerti",
//   "Aedes bergerardi",
//   "Aedes berlandi",
//   "Aedes bevisi",
//   "Aedes biskraensis",
//   "Aedes blacklocki",
//   "Aedes bolense",
//   "Aedes boneti",
//   "Aedes breedensis",
//   "Aedes bromeliae",
//   "Aedes caballus",
//   "Aedes calceatus",
//   "Aedes caliginosus",
//   "Aedes campestris",
//   "Aedes canadensis",
//   "Aedes cantator",
//   "Aedes capensis",
//   "Aedes carteri",
//   "Aedes caspius",
//   "Aedes cataphylla",
//   "Aedes centropunctatus",
//   "Aedes chamboni",
//   "Aedes chaussieri",
//   "Aedes chelli",
//   "Aedes cinereus",
//   "Aedes circumluteolus",
//   "Aedes coluzzii",
//   "Aedes communis",
//   "Aedes contiguus",
//   "Aedes cordellieri",
//   "Aedes corneti",
//   "Aedes cozi",
//   "Aedes crassiforceps",
//   "Aedes cumminsii",
//   "Aedes dalzieli",
//   "Aedes deboeri",
//   "Aedes demeilloni",
//   "Aedes denderensis",
//   "Aedes dendrophilus",
//   "Aedes dentatus",
//   "Aedes detritus",
//   "Aedes dialloi",
//   "Aedes diantaeus",
//   "Aedes domesticus",
//   "Aedes dorsalis",
//   "Aedes dupreei",
//   "Aedes durbanensis",
//   "Aedes ealaensis",
//   "Aedes ebogoensis",
//   "Aedes echinus",
//   "Aedes ellinorae",
//   "Aedes embuensis",
//   "Aedes eritreae",
//   "Aedes ethiopiensis",
//   "Aedes euedes",
//   "Aedes excrucians",
//   "Aedes falabreguesi",
//   "Aedes fascipalpis",
//   "Aedes filicis",
//   "Aedes fitchii",
//   "Aedes flavescens",
//   "Aedes flavicollis",
//   "Aedes flavimargo",
//   "Aedes fowleri",
//   "Aedes fraseri",
//   "Aedes fryeri",
//   "Aedes fulgens",
//   "Aedes fulvus pallens",
//   "Aedes furcifer",
//   "Aedes fuscinervis",
//   "Aedes gandaensis",
//   "Aedes geniculatus",
//   "Aedes geoffroyi",
//   "Aedes gibbinsi",
//   "Aedes gilliesi",
//   "Aedes grahamii",
//   "Aedes grenieri",
//   "Aedes grjebinei",
//   "Aedes grossbecki",
//   "Aedes hancocki",
//   "Aedes hansfordi",
//   "Aedes harrisoni",
//   "Aedes haworthi",
//   "Aedes heischi",
//   "Aedes hendersoni",
//   "Aedes hexodontus",
//   "Aedes hirsutus",
//   "Aedes holocinctus",
//   "Aedes hopkinsi",
//   "Aedes huangae",
//   "Aedes impiger",
//   "Aedes implicatus",
//   "Aedes infirmatus",
//   "Aedes ingrami",
//   "Aedes insolens",
//   "Aedes intrudens",
//   "Aedes irritans",
//   "Aedes j. japonicus",
//   "Aedes jamoti",
//   "Aedes japonicus",
//   "Aedes josiahae",
//   "Aedes juppi",
//   "Aedes kapretwae",
//   "Aedes karooensis",
//   "Aedes keniensis",
//   "Aedes kennethi",
//   "Aedes kenya",
//   "Aedes kivuensis",
//   "Aedes kummi",
//   "Aedes lamborni",
//   "Aedes langata",
//   "Aedes ledgeri",
//   "Aedes leesoni",
//   "Aedes leptolabis",
//   "Aedes leucarthrius",
//   "Aedes lilii",
//   "Aedes lineatopennis",
//   "Aedes lokojensis",
//   "Aedes longipalpis",
//   "Aedes longiseta",
//   "Aedes lottei",
//   "Aedes lucianus",
//   "Aedes luridus",
//   "Aedes luteocephalus",
//   "Aedes luteolateralis",
//   "Aedes luteostriatus",
//   "Aedes mariae",
//   "Aedes marshallii",
//   "Aedes masseyi",
//   "Aedes mattinglyi",
//   "Aedes mattinglyorum",
//   "Aedes maxgermaini",
//   "Aedes mcintoshi",
//   "Aedes mefouensis",
//   "Aedes melanimon",
//   "Aedes mercurator",
//   "Aedes metallicus",
//   "Aedes michaelikati",
//   "Aedes mickevichae",
//   "Aedes microstictus",
//   "Aedes minutus",
//   "Aedes mitchellae",
//   "Aedes mixtus",
//   "Aedes monotrichus",
//   "Aedes mpusiensis",
//   "Aedes mucidus",
//   "Aedes muroafcete",
//   "Aedes mutilus",
//   "Aedes mzooi",
//   "Aedes natalensis",
//   "Aedes natronius",
//   "Aedes neoafricanus",
//   "Aedes nevadensis",
//   "Aedes ngong",
//   "Aedes nigerrimus",
//   "Aedes nigricephalus",
//   "Aedes nigromaculis",
//   "Aedes niphadopsis",
//   "Aedes njombiensis",
//   "Aedes nyasae",
//   "Aedes nyounae",
//   "Aedes ochraceus",
//   "Aedes opok",
//   "Aedes ovazzai",
//   "Aedes pachyurus",
//   "Aedes palpalis",
//   "Aedes pembaensis",
//   "Aedes phyllolabis",
//   "Aedes pionips",
//   "Aedes pogonurus",
//   "Aedes pollinctor",
//   "Aedes poweri",
//   "Aedes provocans",
//   "Aedes pseudoafricanus",
//   "Aedes pseudonigeria",
//   "Aedes pseudotarsalis",
//   "Aedes pubescens",
//   "Aedes pulchrithorax",
//   "Aedes pulcritarsis",
//   "Aedes pullatus",
//   "Aedes punctocostalis",
//   "Aedes punctor",
//   "Aedes punctothoracis",
//   "Aedes quasirusticus",
//   "Aedes quasiunivittatus",
//   "Aedes reali",
//   "Aedes rickenbachi",
//   "Aedes riparius",
//   "Aedes rusticus",
//   "Aedes ruwenzori",
//   "Aedes saimedres",
//   "Aedes sampi",
//   "Aedes scatophagoides",
//   "Aedes schizopinax",
//   "Aedes schwetzi",
//   "Aedes seampi",
//   "Aedes semlikiensis",
//   "Aedes sierrensis",
//   "Aedes simpsoni",
//   "Aedes simulans",
//   "Aedes smithburni",
//   "Aedes soleatus",
//   "Aedes sollicitans",
//   "Aedes spencerii",
//   "Aedes spencerii idahoensis",
//   "Aedes spencerii spencerii",
//   "Aedes spp",
//   "Aedes stenoscutus",
//   "Aedes sticticus",
//   "Aedes stimulans",
//   "Aedes stokesi",
//   "Aedes strelitziae",
//   "Aedes subargenteus",
//   "Aedes subdentatus",
//   "Aedes sudanensis",
//   "Aedes taeniarostris",
//   "Aedes taeniorhynchus",
//   "Aedes tarsalis",
//   "Aedes tauffliebi",
//   "Aedes taylori",
//   "Aedes teesdalei",
//   "Aedes thibaulti",
//   "Aedes tormentor",
//   "Aedes tricholabis",
//   "Aedes triseriatus",
//   "Aedes trivittatus",
//   "Aedes unidentatus",
//   "Aedes unilineatus",
//   "Aedes usambara",
//   "Aedes veeniae",
//   "Aedes ventrovittis",
//   "Aedes vexans",
//   "Aedes vittatus",
//   "Aedes wellmanii",
//   "Aedes wendyae",
//   "Aedes wigglesworthi",
//   "Aedes woodi",
//   "Aedes yangambiensis",
//   "Aedes yvonneae",
//   "Aedes zethus",
//   "Anopheles ainshamsi",
//   "Anopheles algeriensis",
//   "Anopheles amharicus",
//   "Anopheles arabiensis",
//   "Anopheles ardensis",
//   "Anopheles argenteolobatus",
//   "Anopheles aruni",
//   "Anopheles atropos",
//   "Anopheles austeni",
//   "Anopheles austenii",
//   "Anopheles azaniae",
//   "Anopheles azevedoi",
//   "Anopheles bangrofti",
//   "Anopheles barberellus",
//   "Anopheles barberi",
//   "Anopheles berghei",
//   "Anopheles bervoetsi",
//   "Anopheles bradleyi",
//   "Anopheles brohieri",
//   "Anopheles brucei",
//   "Anopheles brumpti",
//   "Anopheles brunnipes",
//   "Anopheles buxtoni",
//   "Anopheles bwambae",
//   "Anopheles caliginosus",
//   "Anopheles cameroni",
//   "Anopheles carnevalei",
//   "Anopheles caroni",
//   "Anopheles carteri",
//   "Anopheles cavernicolus",
//   "Anopheles christyi",
//   "Anopheles cinctus",
//   "Anopheles cinereus",
//   "Anopheles claviger",
//   "Anopheles coluzzii",
//   "Anopheles comorensis",
//   "Anopheles concolor",
//   "Anopheles confusus",
//   "Anopheles coustani",
//   "Anopheles coustani s.l",
//   "Anopheles cristipalpis",
//   "Anopheles crucians",
//   "Anopheles crucians sl",
//   "Anopheles crypticus",
//   "Anopheles culicifacies",
//   "Anopheles cydippis",
//   "Anopheles dancalicus",
//   "Anopheles daudi",
//   "Anopheles deemingi",
//   "Anopheles demeilloni",
//   "Anopheles distinctus",
//   "Anopheles domicolus",
//   "Anopheles dthali",
//   "Anopheles dualaensis",
//   "Anopheles dureni",
//   "Anopheles earlei",
//   "Anopheles eouzani",
//   "Anopheles erepens",
//   "Anopheles erythraeus",
//   "Anopheles ethiopicus",
//   "Anopheles faini",
//   "Anopheles farauti s.l.",
//   "Anopheles flavicosta",
//   "Anopheles fontenillei",
//   "Anopheles fontinalis",
//   "Anopheles freeborni",
//   "Anopheles freetownensis",
//   "Anopheles funestus",
//   "Anopheles funestus s.l",
//   "Anopheles funestus s.s.",
//   "Anopheles funestus-like",
//   "Anopheles fuscicolor",
//   "Anopheles fuscivenosus",
//   "Anopheles gabonensis",
//   "Anopheles gambiae",
//   "Anopheles gambiae s.l",
//   "Anopheles gambiae s.s",
//   "Anopheles garnhami",
//   "Anopheles gibbinsi",
//   "Anopheles grassei",
//   "Anopheles grenieri",
//   "Anopheles griveaudi",
//   "Anopheles hamoni",
//   "Anopheles hancocki",
//   "Anopheles hargreavesi",
//   "Anopheles harperi",
//   "Anopheles hermsi",
//   "Anopheles hervyi",
//   "Anopheles hughi",
//   "Anopheles hyrcanus",
//   "Anopheles implexus",
//   "Anopheles jebudensis",
//   "Anopheles keniensis",
//   "Anopheles kingi",
//   "Anopheles koliensis",
//   "Anopheles kosiensis",
//   "Anopheles labranchiae",
//   "Anopheles lacani",
//   "Anopheles leesoni",
//   "Anopheles letabensis",
//   "Anopheles listeri",
//   "Anopheles lloreti",
//   "Anopheles longipalpis",
//   "Anopheles longirostris",
//   "Anopheles lounibosi",
//   "Anopheles lovettae",
//   "Anopheles machardyi",
//   "Anopheles maculipalpis",
//   "Anopheles maculipennis",
//   "Anopheles maliensis",
//   "Anopheles marshalli",
//   "Anopheles marshallii",
//   "Anopheles marteri",
//   "Anopheles mascarensis",
//   "Anopheles melas",
//   "Anopheles merus",
//   "Anopheles millecampsi",
//   "Anopheles milloti",
//   "Anopheles mortiauxi",
//   "Anopheles moucheti",
//   "Anopheles moucheti nigeriensis",
//   "Anopheles moucheti s.l",
//   "Anopheles moucheti s.s",
//   "Anopheles mousinhoi",
//   "Anopheles multicinctus",
//   "Anopheles multicolor",
//   "Anopheles murphyi",
//   "Anopheles namibiensis",
//   "Anopheles natalensis",
//   "Anopheles nigeriensis",
//   "Anopheles nili",
//   "Anopheles nili s.l",
//   "Anopheles nili s.s",
//   "Anopheles njombiensis",
//   "Anopheles notleyi",
//   "Anopheles obscurus",
//   "Anopheles okuensis",
//   "Anopheles ovengensis",
//   "Anopheles paludis",
//   "Anopheles parensis",
//   "Anopheles pauliani",
//   "Anopheles perplexens",
//   "Anopheles petragnani",
//   "Anopheles pharoensis",
//   "Anopheles plumbeus",
//   "Anopheles pretoriensis",
//   "Anopheles punctipennis",
//   "Anopheles punctuatus",
//   "Anopheles quadriannulatus",
//   "Anopheles quadrimaculatus",
//   "Anopheles rabaiensis",
//   "Anopheles radama",
//   "Anopheles rageaui",
//   "Anopheles ranci",
//   "Anopheles rhodesiensis",
//   "Anopheles rhodesiensis rupicolus",
//   "Anopheles rivulorum",
//   "Anopheles rivulorum-like",
//   "Anopheles rodhaini",
//   "Anopheles roubaudi",
//   "Anopheles ruarinus",
//   "Anopheles rufipes",
//   "Anopheles sacharovi",
//   "Anopheles salbaii",
//   "Anopheles schwetzi",
//   "Anopheles seretsei",
//   "Anopheles sergentii",
//   "Anopheles sergentii macmahoni",
//   "Anopheles seydeli",
//   "Anopheles smithii",
//   "Anopheles somalicus",
//   "Anopheles spp",
//   "Anopheles squamosus",
//   "Anopheles squamous",
//   "Anopheles stephensi",
//   "Anopheles superpictus",
//   "Anopheles swahilicus",
//   "Anopheles symesi",
//   "Anopheles tchekedii",
//   "Anopheles tenebrosus",
//   "Anopheles theileri",
//   "Anopheles turkhudi",
//   "Anopheles ugandae",
//   "Anopheles vaneedeni",
//   "Anopheles vanhoofi",
//   "Anopheles vernus",
//   "Anopheles vinckei",
//   "Anopheles walkeri",
//   "Anopheles walravensi",
//   "Anopheles watsoni",
//   "Anopheles wellcomei",
//   "Anopheles wellcomei erepens",
//   "Anopheles wilsoni",
//   "Anopheles ziemanni",
//   "Coquillettidia annettii",
//   "Coquillettidia aurea",
//   "Coquillettidia aurites",
//   "Coquillettidia buxtoni",
//   "Coquillettidia chrysosoma",
//   "Coquillettidia cristata",
//   "Coquillettidia flavocincta",
//   "Coquillettidia fraseri",
//   "Coquillettidia fuscopennata",
//   "Coquillettidia grandidieri",
//   "Coquillettidia karandalaensis",
//   "Coquillettidia maculipennis",
//   "Coquillettidia metallica",
//   "Coquillettidia microannulata",
//   "Coquillettidia nigritarsis",
//   "Coquillettidia nigrithorax",
//   "Coquillettidia perturbans",
//   "Coquillettidia pseudoconopas",
//   "Coquillettidia richiardii",
//   "Coquillettidia schoutedeni",
//   "Coquillettidia spp",
//   "Coquillettidia vanoyei",
//   "Coquillettidia versicolor",
//   "Coquillettidia voltaensis",
//   "Coquillettidia wahlbergi",
//   "Culex acrostichalis",
//   "Culex adairi",
//   "Culex adami",
//   "Culex adersianus",
//   "Culex albertianus",
//   "Culex albiventris",
//   "Culex amaniensis",
//   "Culex andersoni",
//   "Culex andreanus",
//   "Culex annulioris",
//   "Culex annulirostris",
//   "Culex antennatus",
//   "Culex arbieeni",
//   "Culex argenteopunctatus",
//   "Culex astridianus",
//   "Culex aurantapex",
//   "Culex avianus",
//   "Culex beta",
//   "Culex bitaeniorhynchus",
//   "Culex brumpti",
//   "Culex bukavuensis",
//   "Culex calabarensis",
//   "Culex calurus",
//   "Culex cambournaci",
//   "Culex castelli",
//   "Culex castor",
//   "Culex chorleyi",
//   "Culex cinerellus",
//   "Culex cinereus",
//   "Culex decens",
//   "Culex deserticola",
//   "Culex duttoni",
//   "Culex eouzani",
//   "Culex erraticus",
//   "Culex erythrothorax",
//   "Culex europaeus",
//   "Culex fimbriforceps",
//   "Culex furlongi",
//   "Culex galliardi",
//   "Culex gamma",
//   "Culex garioui",
//   "Culex gelidus",
//   "Culex germaini",
//   "Culex gilliesi",
//   "Culex grahamii",
//   "Culex grenieri",
//   "Culex guiarti",
//   "Culex hamoni",
//   "Culex hancocki",
//   "Culex harleyi",
//   "Culex helenae",
//   "Culex hopkinsi",
//   "Culex horridus",
//   "Culex hortensis",
//   "Culex impudicus",
//   "Culex inconspicuosus",
//   "Culex ingrami",
//   "Culex insignis",
//   "Culex invidiosus",
//   "Culex kanyamwerima",
//   "Culex kilara",
//   "Culex kingianus",
//   "Culex laplantei",
//   "Culex laticinctus",
//   "Culex liberiensis",
//   "Culex lineatus",
//   "Culex litwakae",
//   "Culex macfiei",
//   "Culex martinii",
//   "Culex mijanae",
//   "Culex mimeticus",
//   "Culex mirificus",
//   "Culex modestus",
//   "Culex mongiro",
//   "Culex moucheti",
//   "Culex mundulus",
//   "Culex musarum",
//   "Culex muspratti",
//   "Culex nakuruensis",
//   "Culex neavei",
//   "Culex nebulosus",
//   "Culex nigripalpus",
//   "Culex ninagongoensis",
//   "Culex ornatothoracis",
//   "Culex orstom",
//   "Culex pajoti",
//   "Culex peccator",
//   "Culex perexiguus",
//   "Culex perfidiosus",
//   "Culex perfuscus",
//   "Culex peringueyi",
//   "Culex philipi",
//   "Culex pipiens",
//   "Culex pipiens sl",
//   "Culex poicilipes",
//   "Culex pruina",
//   "Culex pseudoandreanus",
//   "Culex pseudopruina",
//   "Culex pullus",
//   "Culex pusillus",
//   "Culex quasiguiarti",
//   "Culex quinquefasciatus",
//   "Culex quintetti",
//   "Culex restuans",
//   "Culex richeti",
//   "Culex rima",
//   "Culex rubinotus",
//   "Culex salinarius",
//   "Culex salisburiensis",
//   "Culex schwetzi",
//   "Culex seldeslachtsi",
//   "Culex semibrunneus",
//   "Culex shoae",
//   "Culex simpliciforceps",
//   "Culex simpsoni",
//   "Culex sinaiticus",
//   "Culex sitiens",
//   "Culex spp",
//   "Culex striatipes",
//   "Culex subaequalis",
//   "Culex subrima",
//   "Culex subsalisburiensis",
//   "Culex sunyaniensis",
//   "Culex tamsi",
//   "Culex tarsalis",
//   "Culex tauffliebi",
//   "Culex telesilla",
//   "Culex tenagius",
//   "Culex teritans",
//   "Culex territans",
//   "Culex terzii",
//   "Culex thalassius",
//   "Culex theileri",
//   "Culex toroensis",
//   "Culex torrentium",
//   "Culex trifilatus",
//   "Culex trifoliatus",
//   "Culex tritaeniorhynchus",
//   "Culex umbripes",
//   "Culex univittatus",
//   "Culex vagans",
//   "Culex vansomereni",
//   "Culex vattieri",
//   "Culex verutus",
//   "Culex vinckei",
//   "Culex vishnui",
//   "Culex wansoni",
//   "Culex watti",
//   "Culex weschei",
//   "Culex wigglesworthi",
//   "Culex zombaensis",
//   "Culiseta alaskaensis",
//   "Culiseta annulata",
//   "Culiseta fraseri",
//   "Culiseta fumipennis",
//   "Culiseta impatiens",
//   "Culiseta incidens",
//   "Culiseta inornata",
//   "Culiseta litorea",
//   "Culiseta longiareolata",
//   "Culiseta melanura",
//   "Culiseta minnesotae",
//   "Culiseta morsitans",
//   "Culiseta spp",
//   "Culiseta subochrea",
//   "Culiseta tarsalis",
//   "Eretmapodites adami",
//   "Eretmapodites angolensis",
//   "Eretmapodites argyrurus",
//   "Eretmapodites brenguesi",
//   "Eretmapodites brottesi",
//   "Eretmapodites caillardi",
//   "Eretmapodites chrysogaster",
//   "Eretmapodites corbeti",
//   "Eretmapodites dracaenae",
//   "Eretmapodites dundo",
//   "Eretmapodites eouzani",
//   "Eretmapodites ferrarai",
//   "Eretmapodites forcipulatus",
//   "Eretmapodites germaini",
//   "Eretmapodites gilletti",
//   "Eretmapodites grahami",
//   "Eretmapodites grenieri",
//   "Eretmapodites haddowi",
//   "Eretmapodites hamoni",
//   "Eretmapodites harperi",
//   "Eretmapodites hightoni",
//   "Eretmapodites inornatus",
//   "Eretmapodites intermedius",
//   "Eretmapodites jani",
//   "Eretmapodites lacani",
//   "Eretmapodites leucopous",
//   "Eretmapodites mahaffyi",
//   "Eretmapodites marcelleae",
//   "Eretmapodites mattinglyi",
//   "Eretmapodites melanopous",
//   "Eretmapodites mortiauxi",
//   "Eretmapodites oedipodeios",
//   "Eretmapodites parvipluma",
//   "Eretmapodites pauliani",
//   "Eretmapodites penicillatus",
//   "Eretmapodites plioleucus",
//   "Eretmapodites productus",
//   "Eretmapodites quinquevittatus",
//   "Eretmapodites ravissei",
//   "Eretmapodites rickenbachi",
//   "Eretmapodites salauni",
//   "Eretmapodites semisimplicipes",
//   "Eretmapodites silvestris",
//   "Eretmapodites subsimplicipes",
//   "Eretmapodites tendeiroi",
//   "Eretmapodites tonsus",
//   "Eretmapodites vansomereni",
//   "Eretmapodites wansoni",
//   "Ficalbia circumtestacea",
//   "Ficalbia malfeyti",
//   "Ficalbia nigra",
//   "Ficalbia uniformis",
//   "Hodgesia cyptopus",
//   "Hodgesia nigeriae",
//   "Hodgesia psectropus",
//   "Hodgesia sanguinae",
//   "Lutzia tigripes",
//   "Malaya farquharsoni",
//   "Malaya fraseri",
//   "Malaya marceli",
//   "Malaya moucheti",
//   "Malaya taeniarostris",
//   "Malaya trichorostris",
//   "Mansonia africana",
//   "Mansonia perturbans",
//   "Mansonia uniformis",
//   "Mimomyia Mimomyiayiaformis",
//   "Mimomyia femorata",
//   "Mimomyia flavopicta",
//   "Mimomyia hispida",
//   "Mimomyia lacustris",
//   "Mimomyia mediolineata",
//   "Mimomyia pallida",
//   "Mimomyia parenti",
//   "Mimomyia perplexens",
//   "Mimomyia plumosa",
//   "Mimomyia splendens",
//   "Mimomyia xanthozona",
//   "Orthopodomyia alba",
//   "Orthopodomyia aureoantennata",
//   "Orthopodomyia nkolbissonensis",
//   "Orthopodomyia ototomoensis",
//   "Orthopodomyia pulcripalpis",
//   "Orthopodomyia signifera",
//   "Psorophora ciliata",
//   "Psorophora columbiae",
//   "Psorophora cyanescens",
//   "Psorophora discolor",
//   "Psorophora ferox",
//   "Psorophora horrida",
//   "Psorophora howardii",
//   "Psorophora mathesoni",
//   "Psorophora signipennis",
//   "Psorophora spp",
//   "Psorophora varipes",
//   "Toxorhynchites aeneus",
//   "Toxorhynchites angolensis",
//   "Toxorhynchites barbipes",
//   "Toxorhynchites brevipalpis",
//   "Toxorhynchites camaronis",
//   "Toxorhynchites dundo",
//   "Toxorhynchites erythrurus",
//   "Toxorhynchites evansae",
//   "Toxorhynchites helenae",
//   "Toxorhynchites kaimosi",
//   "Toxorhynchites lutescens",
//   "Toxorhynchites nairobiensis",
//   "Toxorhynchites nigeriensis",
//   "Toxorhynchites phytophagus",
//   "Toxorhynchites rickenbachi",
//   "Toxorhynchites rodhaini",
//   "Toxorhynchites rutilus",
//   "Toxorhynchites ruwenzori",
//   "Toxorhynchites viridibasis",
//   "Toxorhynchites wolfsi",
//   "Toxorhynchites zairensis",
//   "Uranotaenia alba",
//   "Uranotaenia alboabdominalis",
//   "Uranotaenia andreae",
//   "Uranotaenia angolensis",
//   "Uranotaenia annulata",
//   "Uranotaenia apicotaeniata",
//   "Uranotaenia balfouri",
//   "Uranotaenia benoiti",
//   "Uranotaenia bilineata",
//   "Uranotaenia caeruleocephala",
//   "Uranotaenia caliginosa",
//   "Uranotaenia cavernicola",
//   "Uranotaenia chorleyi",
//   "Uranotaenia connali",
//   "Uranotaenia devemyi",
//   "Uranotaenia fraseri",
//   "Uranotaenia fusca",
//   "Uranotaenia garnhami",
//   "Uranotaenia hebrardi",
//   "Uranotaenia henrardi",
//   "Uranotaenia henriquei",
//   "Uranotaenia hopkinsi",
//   "Uranotaenia lowii",
//   "Uranotaenia lucyae",
//   "Uranotaenia lunda",
//   "Uranotaenia machadoi",
//   "Uranotaenia mashonaensis",
//   "Uranotaenia mashonanensis",
//   "Uranotaenia mayeri",
//   "Uranotaenia montana",
//   "Uranotaenia musarum",
//   "Uranotaenia nigripes",
//   "Uranotaenia nigromaculata",
//   "Uranotaenia nivipous",
//   "Uranotaenia ornata",
//   "Uranotaenia ototomo",
//   "Uranotaenia pallidocephala",
//   "Uranotaenia palmeirimi",
//   "Uranotaenia philonuxia",
//   "Uranotaenia principensis",
//   "Uranotaenia pseudohenrardi",
//   "Uranotaenia ramosa",
//   "Uranotaenia rickenbachi",
//   "Uranotaenia sapphirina",
//   "Uranotaenia shillitonis",
//   "Uranotaenia signata",
//   "Uranotaenia tanzaniae",
//   "Uranotaenia ugandae",
//   "Uranotaenia unguiculata",
//   "Uranotaenia yovani",
//   "Wyeomyia smithii",
//   ...tickAutoCompleteOptions,
// ];

// note: must remain ordered
export const DefaultWellAutocompleteOptions = [
  "A01",
  "A02",
  "A03",
  "A04",
  "A05",
  "A06",
  "A07",
  "A08",
  "A09",
  "A10",
  "A11",
  "A12",
  "B01",
  "B02",
  "B03",
  "B04",
  "B05",
  "B06",
  "B07",
  "B08",
  "B09",
  "B10",
  "B11",
  "B12",
  "C01",
  "C02",
  "C03",
  "C04",
  "C05",
  "C06",
  "C07",
  "C08",
  "C09",
  "C10",
  "C11",
  "C12",
  "D01",
  "D02",
  "D03",
  "D04",
  "D05",
  "D06",
  "D07",
  "D08",
  "D09",
  "D10",
  "D11",
  "D12",
  "E01",
  "E02",
  "E03",
  "E04",
  "E05",
  "E06",
  "E07",
  "E08",
  "E09",
  "E10",
  "E11",
  "E12",
  "F01",
  "F02",
  "F03",
  "F04",
  "F05",
  "F06",
  "F07",
  "F08",
  "F09",
  "F10",
  "F11",
  "F12",
  "G01",
  "G02",
  "G03",
  "G04",
  "G05",
  "G06",
  "G07",
  "G08",
  "G09",
  "G10",
  "G11",
  "G12",
  "H01",
  "H02",
  "H03",
  "H04",
  "H05",
  "H06",
  "H07",
  "H08",
  "H09",
  "H10",
  "H11",
  "H12",
];

export const WELL_REGEX = new RegExp("^[A-H](0[1-9]|1[0-2])$");

export const COMMA_SLASH_REGEX = /.*,.*|.*\/\\.*/i;

export const genusSpeciesPattern = /^([a-zA-Z\d\-_]+) ?([a-zA-Z\d\-_]* ?.*)$/;

export const DuplicateOptionsEnum = {
  none: 0,
  skip: 1,
  overwrite: 2,
};

export const OverwriteOptionsEnum = {
  overwrite: 0,
  skip: 1,
  autofill: 2,
};

export const timeZoneOptions = timezones;

export const sexOptions = ["Female", "Male", "Unsure", "Undefined"];

export const algorithmSexOptions = ["Female", "Male", "Unknown"];
