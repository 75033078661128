import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
import {
  displaySuccessNotification,
  displayErrorNotification,
  fetchWifiNetworks,
  setupWPAWifi,
  cancelWifiAction,
  finishWifiSetup,
  chooseWifi,
  deleteWifi,
  fetchConnectedNetworksWifi,
  fetchConnectedNetworkWifi,
} from "../../../../../actions";
import { uuidv4 } from "../../../../../utils/uuid";
import WifiScanPage from "./WifiScanPage";
import WifiTable from "./WifiTable";
import WifiModal from "./WifiModal";

const Index = ({
  selectedDevice,
  deviceName,
  wifiState,
  displaySuccessNotification,
  displayErrorNotification,
  fetchWifiNetworks,
  setupWPAWifi,
  cancelWifiAction,
  finishWifiSetup,
  chooseWifi,
  deleteWifi,
}) => {
  const [started, setStarted] = useState(false);
  const [ssid, setSSID] = useState("");
  const [psk, setPSK] = useState("");
  const [currentJob, setCurrentJob] = useState(null);
  // const history = useHistory();
  const [wifiModal, setWifiModal] = useState(false);

  const startFetchWifiJob = useCallback(() => {
    if (currentJob) {
      return;
    }
    const uuid = uuidv4();
    setCurrentJob(uuid);
    fetchWifiNetworks(selectedDevice, uuid);
  }, [fetchWifiNetworks, selectedDevice, currentJob]);

  const startSetupWifiJob = () => {
    if (currentJob) {
      return;
    }
    const uuid = uuidv4();
    setCurrentJob(uuid);
    setupWPAWifi(selectedDevice, uuid, ssid, psk);
  };

  const cancelRunningWifiJob = useCallback(() => {
    if (currentJob) {
      cancelWifiAction(currentJob);
      setCurrentJob(null);
    }
  }, [cancelWifiAction, currentJob]);

  useEffect(() => {
    if (started) startFetchWifiJob();
    return () => {
      if (started) {
        cancelRunningWifiJob();
        finishWifiSetup();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice, started]);

  useEffect(() => {
    // if (wifiState === 'finishFetchWifiList') {
    //   setCurrentJob(null);
    //   startFetchWifiJob2()
    // } else if (wifiState === 'finishFetchConnectedWifiLists') {
    //   setCurrentJob(null);
    //   startFetchWifiJob3()
    // } else
    if (!started) return;

    if (wifiState === "connected") {
      setCurrentJob(null);
      startFetchWifiJob();
      displaySuccessNotification(`${deviceName} was successfully connected`);
      // history.goBack();
      setWifiModal(false);
    } else if (wifiState === "selectFail") {
      displayErrorNotification(`${deviceName} was fail to connect to ${ssid}`);
      setCurrentJob(null);
      startFetchWifiJob();
    } else if (wifiState === "selectSuccess") {
      displaySuccessNotification(
        `${deviceName} was successfully connected to ${ssid}`
      );
      setCurrentJob(null);
      startFetchWifiJob();
    } else if (wifiState === "deleteSuccess") {
      setCurrentJob(null);
      startFetchWifiJob();
      displaySuccessNotification(`${ssid} was successfully forgotten`);
    } else if (wifiState === "deleteFail") {
      setCurrentJob(null);
      startFetchWifiJob();
      displayErrorNotification(`${ssid} was fail to forget`);
    } else if (wifiState === "ready" || wifiState === "disconnected") {
      setCurrentJob(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wifiState, deviceName]);

  const onConnectButtonPressed = async () => {
    startSetupWifiJob();
  };

  const onRescanButtonPressed = () => {
    startFetchWifiJob();
  };

  const onCancelButtonPressed = () => {
    cancelRunningWifiJob();
  };

  const onHandleStartScan = () => {
    setStarted(true);
  };

  // const onChangeSSID = (obj) => {
  //   if (obj && obj.target && typeof obj.target.value === "string")
  //     setSSID(obj.target.value);
  //   else setSSID("");
  // };

  const onChangePSK = (obj) => {
    if (obj && obj.target && typeof obj.target.value === "string")
      setPSK(obj.target.value);
    else setPSK("");
  };

  const onChooseWifi = (ssid) => {
    if (currentJob) {
      return;
    }
    const uuid = uuidv4();
    setSSID(ssid);
    chooseWifi(selectedDevice, uuid, ssid);
    setCurrentJob(uuid);
  };

  const onForgetWifi = (ssid) => {
    if (currentJob) {
      return;
    }
    const uuid = uuidv4();
    setSSID(ssid);
    deleteWifi(selectedDevice, uuid, ssid);
    setCurrentJob(uuid);
  };
  if (started) {
    return (
      <>
        <WifiTable
          wifiState={wifiState}
          setOpen={() => setWifiModal(true)}
          onChooseWifi={onChooseWifi}
          handleRescanWifiButtonPressed={onRescanButtonPressed}
          setSSID={setSSID}
          onForgetWifi={onForgetWifi}
        />
        <WifiModal
          wifiState={wifiState}
          open={wifiModal}
          setClose={() => {
            setWifiModal(false);
            setPSK("");
          }}
          ssid={ssid}
          psk={psk}
          handleChangePSK={onChangePSK}
          handleConnectButtonPressed={onConnectButtonPressed}
          handleCancelButtonPressed={onCancelButtonPressed}
        />
      </>
    );
  } else {
    return <WifiScanPage handleScanButtonPressed={onHandleStartScan} />;
  }
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  if (!selectedDevice) return {};
  const { displayName: deviceName } = devices?.[selectedDevice];
  const { status: wifiState, networks: wifiList } = state.wifi;

  return {
    selectedDevice,
    deviceName,
    wifiState,
    wifiList,
  };
};

export default connect(mapStateToProps, {
  displaySuccessNotification,
  displayErrorNotification,
  fetchWifiNetworks,
  setupWPAWifi,
  cancelWifiAction,
  finishWifiSetup,
  chooseWifi,
  deleteWifi,
  fetchConnectedNetworksWifi,
  fetchConnectedNetworkWifi,
})(Index);
